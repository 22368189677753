@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=JetBrains+Mono:wght@100;200;300;400;500;600;700;800&display=swap');

@layer base {
  html {
    scroll-behavior: smooth;
  }
  
  body {
    @apply bg-secondary-50 text-secondary-900 font-sans;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-sans font-bold;
  }

  h1 {
    @apply text-4xl md:text-5xl lg:text-6xl;
  }

  h2 {
    @apply text-3xl md:text-4xl lg:text-5xl;
  }

  h3 {
    @apply text-2xl md:text-3xl;
  }

  h4 {
    @apply text-xl md:text-2xl;
  }

  h5 {
    @apply text-lg md:text-xl;
  }

  h6 {
    @apply text-base md:text-lg;
  }

  a {
    @apply text-primary-600 hover:text-primary-800 transition-colors duration-300;
  }
}

@layer components {
  .container-custom {
    @apply container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl;
  }

  .section {
    @apply py-16 md:py-24;
  }

  .btn {
    @apply inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .btn-primary {
    @apply bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-500;
  }

  .btn-secondary {
    @apply bg-secondary-200 text-secondary-900 hover:bg-secondary-300 focus:ring-secondary-500;
  }

  .btn-accent {
    @apply bg-accent-600 text-white hover:bg-accent-700 focus:ring-accent-500;
  }
}
